<template>
    <div>
        <nav-bar title="佣金提现" url="/sale"></nav-bar>

        <div class="top">
            <div class="title">
                <div class="title-text">
                    认证信息
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group v-if="info.sale_cert_state == 1">
                <van-cell title="姓名" :value="info.sale_cert_name"></van-cell>
                <van-cell title="身份证号" :value="info.sale_cert_sfz"></van-cell>
                <van-cell title="电话" :value="info.sale_cert_tel"></van-cell>
            </van-cell-group>
            <van-cell-group v-if="info.sale_cert_state !== 1">
                <van-cell title="未认证" value="去认证" is-link @click="url('/cert')"></van-cell>
            </van-cell-group>
        </div>

        <div class="top">
            <div class="title">
                <div class="title-text">
                    资金信息
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-cell title="可提现余额" :value="'￥' + info.sale_balance"></van-cell>
                <van-cell title="待到账金额" :value="'￥' + info.sale_money_no"></van-cell>
            </van-cell-group>
        </div>


        <div class="top">
            <div class="title" @click="show_content = true;">
                <div class="title-text">
                    提现操作
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
                <div class="top-right">
                    提现说明
                </div>
            </div>
            <van-cell-group>
                <van-field label-width="70" label-class="label" v-model="money" label="提现金额" @input="change"
                    :placeholder="'本次最多可提现金额为￥' + max_money + '元'"></van-field>
                <van-cell title="个税扣除" :value="'￥' + service_money"></van-cell>
            </van-cell-group>

        </div>

        <div class="action">
            <van-button type="primary" @click="submit" :color="config.main_color" size="large" round>提交提现申请</van-button>
        </div>

        <!--佣金说明-->
        <van-action-sheet v-model="show_content" title="提现说明" :round="true">
            <div class="person-demand" v-html="info.withdraw_content"></div>
        </van-action-sheet>


    </div>
</template>

<script>
    import {
        sale_info, sale_withdraw_submit
    } from '@/api/sale.js';
    import {
        Toast,
        Dialog,
        ImagePreview,
    } from 'vant';
    export default {
        name: 'sale_withdraw_detail',
        data() {
            return {
                info: {
                    sale_balance: 0,
                    sale_money_no: 0,
                },
                money: null,//提现金额
                show_content: false,
            }
        },
        computed: {
            service_money() {
                var moon_money = this.info.moon_money ? this.info.moon_money : 0;
                if (moon_money < 800) {
                    if (moon_money * 1 + this.money * 1 <= 800) {
                        return 0;
                    } else {
                        var service = (moon_money * 1 + this.money * 1 - 800) * 0.2;
                        return service.toFixed(2);
                    }
                } else {
                    var service = this.money * 0.2;
                    return service.toFixed(2);
                }

            },
            max_money() {
                if (this.info.sale_balance == 0) {
                    return 0;
                }

                var max = this.info.sale_balance;
                return max.toFixed(2);
            },
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                sale_info({
                    loading: true,
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },

            //监听输入金额
            change(e) {
                if (e > this.max_money) {
                    // this.money = this.max_money;
                }
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },

            //提现申请
            submit() {
                var that = this;
                sale_withdraw_submit({
                    id: this.info.id,
                    money: this.money,
                    loading: true,
                    loading_text: '提交中……',
                }).then(res => {
                    if (res.code == 1) {
                        Dialog.alert({
                            title: '提示',
                            message: res.msg,
                        }).then(() => {
                            that.url('/sale_withdraw_detail?id=' + res.data);
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    .top-right {
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 12px;
        color: red;
    }

    .person-demand {
        padding: 15px;
    }
</style>